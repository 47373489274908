html {
  overflow: hidden;
  overscroll-behavior: none;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

#__next {
  height: 100%;
  overflow: auto;
}

.rdg-row {
  background-color: white;
  color: black;
  --rdg-row-hover-background-color: var(--chakra-colors-gray-100);
}

.rdg-header-row {
  background-color: white;
  color: black;
}

.label-marker {
  margin-bottom: 30px;
}

@media print {
  html,
  body {
    height: initial !important;
  }

  .gm-style-cc,
  .gmnoscreen {
    display: none !important;
  }
}
